exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-local-traders-js": () => import("./../../../src/pages/local-traders.js" /* webpackChunkName: "component---src-pages-local-traders-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-events-categories-js": () => import("./../../../src/templates/eventsCategories.js" /* webpackChunkName: "component---src-templates-events-categories-js" */),
  "component---src-templates-events-post-js": () => import("./../../../src/templates/eventsPost.js" /* webpackChunkName: "component---src-templates-events-post-js" */),
  "component---src-templates-legal-pages-js": () => import("./../../../src/templates/legalPages.js" /* webpackChunkName: "component---src-templates-legal-pages-js" */),
  "component---src-templates-listings-categories-js": () => import("./../../../src/templates/listingsCategories.js" /* webpackChunkName: "component---src-templates-listings-categories-js" */),
  "component---src-templates-listings-post-js": () => import("./../../../src/templates/listingsPost.js" /* webpackChunkName: "component---src-templates-listings-post-js" */),
  "component---src-templates-news-categories-js": () => import("./../../../src/templates/newsCategories.js" /* webpackChunkName: "component---src-templates-news-categories-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/newsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-programs-post-js": () => import("./../../../src/templates/programsPost.js" /* webpackChunkName: "component---src-templates-programs-post-js" */)
}

