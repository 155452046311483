import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const pattern = (
  <svg
    viewBox="0 0 1440 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-full"
  >
    <path
      d="M400.61 55.8205V111.621H314.269V0H170.359V139.541V167.442H199.133V27.9004H285.475V139.541H429.384V83.7209H515.726V167.442H544.5V142.041V55.8205H400.61Z"
      fill="#0D0C0E"
    />
    <path
      d="M544.5 167.442H645.132V45.9546H722.685V146.721H842.48V108.787H902.799V167.442H1006.2V71.0207H1092.37V200H1190.08V37.5992H1267.64V120.93H1293.49V12.5331H1164.23V177.434H1118.22V45.9546H980.351V142.041H928.65V83.7209H816.629V121.32H748.535V22.2319H619.281V142.041H544.5V167.442Z"
      fill="#0D0C0E"
    />
    <path
      d="M86.8205 167.442H170.359V139.541H114.667V83.7209H-6V111.621H86.8205V167.442Z"
      fill="#0D0C0E"
    />
    <path
      d="M1293.49 120.93H1267.64V148.837H1442V120.93H1293.49Z"
      fill="#0D0C0E"
    />
  </svg>
)

export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footer: sanitySettingsFooter {
        acknowledgment
      }
      general: sanitySettingsGeneral {
        codeEmbed
      }
    }
  `)
  return (
    <>
      <footer className="py-16 space-y-8">
        {pattern}
        <div className="px-6 md:px-16 text-center text-sm">
          {data.footer.acknowledgment && (
            <p className="max-w-4xl mx-auto">{data.footer.acknowledgment}</p>
          )}
          <p>
            &copy; {new Date().getFullYear()} SunCentral Maroochydore Pty Ltd.
          </p>
        </div>
      </footer>
      {data.general.codeEmbed && (
        <div dangerouslySetInnerHTML={{ __html: data.general.codeEmbed }} />
      )}
    </>
  )
}
