import React from "react"
import { Link } from "gatsby"

const SanityLink = ({ children, link, ...props }) => {
  const internalSanityLink = link.linkType === "internal"
  const externalSanityLink = link.linkType === "external"

  if (internalSanityLink) {
    if (link.internalLink._type === "newsCategories") {
      return (
        <Link
          to={`/news/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    } else if (link.internalLink._type === "eventsCategories") {
      return (
        <Link
          to={`/events/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    } else if (link.internalLink._type === "listingsCategories") {
      return (
        <Link
          to={`/local-traders/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    } else if (link.internalLink._type === "programs") {
      return (
        <Link
          to={`/programs/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    } else if (link.internalLink._type === "events") {
      return (
        <Link
          to={`/events/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    } else if (link.internalLink._type === "news") {
      return (
        <Link
          to={`/news/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    } else if (link.internalLink._type === "listings") {
      return (
        <Link
          to={`/listings/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    } else if (link.internalLink._type === "homePage") {
      return (
        <Link to={`/`} {...props} activeClassName="current">
          {children}
        </Link>
      )
    } else {
      return (
        <Link
          to={`/${link.internalLink.slug.current}`}
          {...props}
          activeClassName="current"
        >
          {children}
        </Link>
      )
    }
  }
  if (externalSanityLink) {
    return (
      <a href={link.href} target={link.blank ? `_blank` : null} {...props}>
        {children}
      </a>
    )
  }
}

export default SanityLink
