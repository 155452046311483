import React from "react"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

export default function BaseLayout({ children }) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  )
}
